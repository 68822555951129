<template>
  <div>
    <b-card>
      <div class="m-12">
        <div class="form-row justify-content-between">
          <div class="col-md-10">
            <input
              type="search"
              class="form-control d-inline-block"
              placeholder="Titulo"
              v-model="title"
            />
          </div>

          <div class="col-md-2">
            <button
              style="padding: 14px !important"
              type="button"
              class="btn btn-primary btn-block"
              @click="search"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="my-2">
        <b-col
          cols="4"
          md="2"
          class="d-none d-sm-block align-items-center justify-content-left"
        >
          <v-select
            label="title"
            required
            v-model="perPage"
            :options="perPageOptions"
            @input="setPerPageSelected"
            :clearable="false"
          />
        </b-col>
      </div>
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="created_at"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT(true) }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              :id="`customer-${data.item.uuid}-delete-icon`"
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              :id="`customer-${data.item.uuid}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="modalEdit(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />

      <b-modal
        id="modal-create-datasheet"
        modal-class="modal-create"
        :title="titleModal"
        centered
        size="lg"
        hide-footer
      >
        <b-form @submit.prevent="submitModal">
          <b-row class="form-row">
            <b-col md="10">
              <b-form-group>
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Título
                </label>
                <b-form-input
                  v-model="item.title"
                  :class="{ 'is-invalid': $v.item.title.$error }"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <label for=""> Vagas </label>
                <b-form-input type="text" v-model="item.vacancies" />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group>
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Orgão
                </label>
                <v-select
                  ref="selectorgan"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.organ"
                  placeholder="Digite o título"
                  :options="optionsOrgans"
                  @search="fetchOrgans"
                  :class="{ 'is-invalid': $v.item.organ.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectorgan.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Remuneração
                </label>
                <div class="form-row">
                  <div class="col-md-5">
                    <b-form-input
                      v-model="item.remuneration_prefix"
                      placeholder="Ex. Até"
                    />
                  </div>
                  <div class="col-md-7">
                    <b-form-input
                      v-model="item.remuneration_value"
                      :class="{
                        'is-invalid': $v.item.remuneration_value.$error,
                      }"
                      placeholder=""
                    />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="" style="display: flow-root">
                  <span class="float-left">
                    <i class="text-danger bi bi-record-circle"></i>
                    Situação
                  </span>
                </label>
                <v-select
                  ref="selectSituations"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.situation"
                  placeholder="Digite o título"
                  :options="optionsSituations"
                  :class="{ 'is-invalid': $v.item.situation.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectSituations.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <label for="" style="display: flow-root">
                  <span class="float-left"> Contratação </span>
                </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.contract"
                  placeholder="Digite o título"
                  :options="optionsContractModels"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="" style="display: flow-root">
                  <span class="float-left"> Banca </span>
                  <!-- <a
                    href="#"
                    class="float-right"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                  >
                    <feather-icon icon="PlusCircleIcon" /> novo
                  </a> -->
                </label>
                <v-select
                  ref="selectJuries"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.jury"
                  placeholder="Digite o título"
                  :options="optionsJuries"
                  @search="fetchJuries"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectJuries.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for=""> Período de inscrição </label>
                <div class="form-row period">
                  <div class="col">
                    <b-form-datepicker
                      id="start"
                      class="mr-1"
                      locale="pt-br"
                      placeholder="De"
                      today-button
                      label-today-button="Hoje"
                      label-reset-button="Limpar"
                      reset-button
                      v-model="item.registration_period_start"
                      :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }"
                    />
                  </div>
                  <div class="col">
                    <b-form-datepicker
                      id="end"
                      locale="pt-br"
                      placeholder="Até"
                      label-reset-button="Limpar"
                      reset-button
                      today-button
                      label-today-button="Hoje"
                      v-model="item.registration_period_end"
                      :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }"
                    />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <label for=""> Taxa de inscrição </label>
                <b-form-input v-model="item.registration_fee" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for=""> Data da Prova </label>
                <b-form-datepicker
                  label-reset-button="Limpar"
                  reset-button
                  today-button
                  label-today-button="Hoje"
                  id="date"
                  locale="pt-br"
                  placeholder=""
                  v-model="item.exam_date"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                />
              </b-form-group>
            </b-col>
            <hr />
          </b-row>

          <b-tabs pills class="mt-2">
            <b-tab active>
              <template #title>
                <span class="d-none d-sm-inline"> Descrição </span>
              </template>

              <editor
                class="about-editor-1"
                api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                v-model="item.description"
                :init="
                  this.optionEditor({
                    height: 600,
                    selector: 'about-editor-1',
                  })
                "
              />
            </b-tab>

            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">
                  <i class="text-danger bi bi-record-circle"></i>
                  Cargo(s)
                </span>
              </template>
              <b-form-group>
                <v-select
                  multiple
                  ref="selectOffices"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.offices"
                  placeholder="Digite o título"
                  :options="optionsOffices"
                  @search="fetchOffice"
                  :class="{ 'is-invalid': $v.item.offices.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectOffices.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-tab>
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">
                  <i class="text-danger bi bi-record-circle"></i>
                  Escolaridade(s)
                </span>
              </template>
              <b-form-group>
                <v-select
                  ref="selectEducation"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.scholarities"
                  placeholder="Digite o título"
                  :options="optionsScholarities"
                  :class="{ 'is-invalid': $v.item.scholarities.$error }"
                  multiple
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectEducation.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-tab>
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">
                  <i class="text-danger bi bi-record-circle"></i>
                  Estado(s)
                </span>
              </template>
              <b-form-group>
                <v-select
                  multiple
                  ref="selectStates"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.states"
                  placeholder="Digite o título"
                  :options="optionsStates"
                  @search="fetchStates"
                  :class="{ 'is-invalid': $v.item.states.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectStates.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-tab>
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">Edital</span>
              </template>
              <div class="form-row">
                <div class="col-md-6">
                  <label> Edital </label>
                  <b-form-group>
                    <v-select
                      ref="selectNotice"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.notice"
                      placeholder="Digite o título"
                      :options="optionsNotices"
                      @search="fetchNotices"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectNotice.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <b-col md="6">
                  <b-form-group>
                    <label> Último edital </label>
                    <v-select
                      ref="selectNoticeLast"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.notice_last"
                      placeholder="Digite o título"
                      :options="optionsNoticeLast"
                      @search="fetchNoticeLast"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectNoticeLast.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">Provas</span>
              </template>
              <div class="form-row">
                <b-col md="6">
                  <b-form-group>
                    <label for=""> Última prova </label>
                    <v-select
                      ref="selectTestLast"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.proof"
                      placeholder="Digite o título"
                      :options="optionsProofs"
                      @search="fetchProofs"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectTestLast.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
              </div>
            </b-tab>
          </b-tabs>

          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-4 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-datasheet')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BSpinner,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  BFormDatepicker,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    BSpinner,
    BCard,
    BModal,
    CustomPaginateTable,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BSpinner,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
    BFormDatepicker,
    BTabs,
    BTab,
    editor: Editor,
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-datasheet");
  },
  data() {
    return {
      item: {
        title: "",
        organ: "",
        description: "",
        remuneration_prefix: "",
        remuneration_value: "",
        offices: "",
        scholarities: "",
        situation: "",
        vacancies: "",
        jury: "",
        states: "",
        registration_period_start: "",
        registration_period_end: "",
        registration_fee: "",
        exam_date: "",
        notice: "",
        proof: "",
        notice_last: "",
        contract: "",
      },
      uuid: null,
      btnSearch: false,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 100,
      rowsTable: 0,
      title: "",
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
        {
          title: "300",
          value: 300,
        },
      ],
      tableColumns: [
        {
          key: "title",
          label: "Título",
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
          thStyle: "width: 210px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 90px",
        },
      ],
      dataItems: [],
      titleModal: "",
      optionsJuries: [],
      optionsStates: [],
      optionsScholarities: [],
      optionsOrgans: [],
      optionsProofs: [],
      optionsNotices: [],
      optionsNoticeLast: [],
      optionsSituations: [],
      optionsOffices: [],
      optionsContractModels: [],
    };
  },
  validations: {
    item: {
      title: {
        required,
      },
      organ: {
        required,
      },
      scholarities: {
        required,
      },
      situation: {
        required,
      },
      states: {
        required,
      },
      offices: {
        required,
      },
      remuneration_value: {
        required,
      },
    },
  },
  created() {},
  async mounted() {
    this.optionsScholarities = await this.$store.dispatch(
      "Scholarity/allToSelect"
    );

    this.optionsSituations = await this.$store.dispatch(
      "Situation/allToSelect"
    );

    this.optionsContractModels = await this.$store.dispatch(
      "ModelContract/forSelect"
    );

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });

    this.getData().then(() => {
      this.filter = this.$route.query.title ?? "";
    });

    this.$root.$on("open:modal-create-datasheet", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Ficha Técnica";
      this.clearForm();
      this.$bvModal.show("modal-create-datasheet");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-datasheet");
  },
  methods: {
    async fetchOrgans(term) {
      if (term.length > 1) {
        this.$store.dispatch("Organ/search", term).then(() => {
          this.optionsOrgans = this.searchOrgans;
        });
      }
    },
    async fetchJuries(term) {
      if (term.length > 1) {
        this.$store.dispatch("Jury/search", term).then(() => {
          this.optionsJuries = this.searchJuries;
        });
      }
    },
    async fetchStates(term) {
      if (term.length > 1) {
        this.$store.dispatch("State/search", term).then(() => {
          this.optionsStates = this.searchStates;
        });
      }
    },
    async fetchNotices(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("Media/searchType", { term: term, type: 4 })
          .then(() => {
            this.optionsNotices = this.searchNotices;
          });
      }
    },
    async fetchNoticeLast(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("Media/searchType", { term: term, type: 4 })
          .then(() => {
            this.optionsNoticeLast = this.searchNotices;
          });
      }
    },
    async fetchProofs(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("Media/searchType", { term: term, type: 5 })
          .then(() => {
            this.optionsProofs = this.searchProofs;
          });
      }
    },
    search() {
      console.log("search");
      this.btnSearch = true;
      this.getData();
    },
    async fetchOffice(term) {
      if (term.length > 2) {
        this.$store.dispatch("Office/search", term).then(() => {
          this.optionsOffices = this.searchOffices;
        });
      }
    },
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();
      this.$store.dispatch("Datasheet/show", uuid).then(() => {
        this.item = this.datasheet;
        this.uuid = this.item.uuid;
        this.$bvModal.show("modal-create-datasheet");
      });
    },
    submitModal() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Datasheet/create", this.item)
            .then(() => {
              this.notifyDefault("success");

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-datasheet");
                this.clearForm();
                this.getData();
              });
            })
            .catch(() => {
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Datasheet/update", { uuid: this.uuid, data: this.item })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-datasheet");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        title: "",
        organ: "",
        remuneration_prefix: "",
        remuneration_value: "",
        office: "",
        scholarities: "",
        situation: "",
        vacancies: "",
        jury: "",
        states: "",
        registration_period_start: "",
        registration_period_end: "",
        registration_fee: "",
        exam_date: "",
        notice: "",
        proof: "",
        notice_last: "",
        contract: "",
      };
    },
    async getData() {
      this.$store
        .dispatch("Datasheet/all", {
          title: this.title,
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then(() => {
          if (this.datasheets) {
            this.dataItems = this.datasheets.data;
            this.rowsTable = this.datasheets.data.length;
            this.totalRows = this.datasheets.meta.total;
            this.currentPage = this.datasheets.meta.current_page;
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Datasheet/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState("Datasheet", ["datasheets", "datasheet"]),
    ...mapState("Organ", ["searchOrgans"]),
    ...mapState("Jury", ["searchJuries"]),
    ...mapState("Office", ["searchOffices"]),
    ...mapState("State", ["searchStates"]),
    ...mapState("Media", ["searchNotices", "searchProofs"]),
  },
};
</script>
<style>
.b-form-datepicker label {
  line-height: 0;
}
</style>
